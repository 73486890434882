import { Button } from "react-bootstrap";
import styled from "styled-components";

type StyledButtonProps = {
  size?: string;
};

const StyledButton = styled(Button)<StyledButtonProps>`
  text-transform: uppercase;

  ${({ size }) =>
    size !== "sm" &&
    `
  padding: 15px;
  padding-left: 20px;
  padding-right: 20px;
  `}
`;

export { StyledButton };
