import React from 'react';
import { Form } from 'react-bootstrap';
import FormItemContainer from '../form-item-container/form-item-container.component';
import FormInputProps from '../../../types/FormInputProps';

type Props = FormInputProps<{
  text?: string;
  children?: React.ReactNode;
}>;

const Textarea: React.FC<Props> = React.forwardRef<HTMLTextAreaElement, Props>(
  ({ name, required, label, error, text, onChange, onBlur, disabled, ...otherProps }, ref) => (
    <FormItemContainer {...{ label, required, error, text }}>
      <Form.Control
        as="textarea"
        type="text"
        {...{ name, required, ref, onChange, onBlur, disabled }}
        {...otherProps}
        isInvalid={!!error}
      />
    </FormItemContainer>
  ),
);

export default Textarea;
