import React, { useContext } from 'react';
import { Alert, Spinner } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import TeamsSection from '../../components/teams-section/teams-section.component';
import { TeamContext } from '../../contexts/team.context';

const Teams: React.FC = () => {
  let [searchParams] = useSearchParams();

  const successCreateTeam = searchParams.get('create');
  const successUpdateTeam = searchParams.get('update');
  const { getTeamsLoading, ownedTeams, sharedTeams } = useContext(TeamContext);


  return (
    <>
      {successCreateTeam && <Alert variant="success">Team {successCreateTeam} created successfully!</Alert>}
      {successUpdateTeam && <Alert variant="success">Team {successUpdateTeam} updated successfully!</Alert>}
      <h1 className="mb-5">Teams</h1>
      {!getTeamsLoading ? (
        <>
          <TeamsSection newButton className="mb-4" title="My own teams" teams={ownedTeams || []} />
          <TeamsSection
            title="Teams I am in"
            teams={sharedTeams || []}
            message="You are not in any team. Either create one or ask your team owner to add you to the team."
          />
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default Teams;
