type HowDidYouFindUsItem = {
  label: string;
  value: string;
};
const howDidYouFindUs: HowDidYouFindUsItem[] = [
  {
    label: 'Search Engine',
    value: 'search_engine',
  },
  {
    label: 'Friend(s)/Colleague(s)',
    value: 'friend_colleague',
  },
  {
    label: 'Social Media',
    value: 'social_media',
  },
  {
    label: 'Newsletter',
    value: 'newsletter',
  },
  {
    label: 'Association',
    value: 'association',
  },
  {
    label: 'Professor',
    value: 'professor',
  },
];

export default howDidYouFindUs;
