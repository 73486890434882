import { Card } from 'react-bootstrap'
import styled from 'styled-components'
import variables from '../../variables.module.scss';

const TeamCardBoxBase = styled(Card)`
border: 0px;
box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.12);
min-height: 100px;
`

const TeamCardBoxPrimary = styled(TeamCardBoxBase)`
    color: white;
    background-color: ${variables.colorPrimary}
`

const TeamCardBoxLight = styled(TeamCardBoxBase)``

export {TeamCardBoxBase, TeamCardBoxPrimary, TeamCardBoxLight}