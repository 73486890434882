// apollo.js
import { ApolloClient, InMemoryCache } from '@apollo/client';


const cache = new InMemoryCache();

const ApolloAuthClient = () => {

  return new ApolloClient({
    uri: `${process.env.REACT_APP_DATA_PORTAL_URL || 'http://localhost:3000'}/api/graphql`,
    credentials: 'include',
    cache,
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
      },
    },
  });
};

export default ApolloAuthClient;
