import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import schema from '../../forms/application-data/application-data.schema';
import CustomButton from '../../components/custom-button/custom-button.component';
import { fromFormDataToNewApplicationData } from '../../utils/gql.utils';
import { useAuth } from '../../contexts/auth/auth.context';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import ApplicationData from '../../forms/application-data/application-data.component';
import { ApplicationContext } from '../../contexts/application.context';
import { BatchContext } from '../../contexts/batch.context';
import ButtonLink from '../../components/button-link/button-link.component';
import ApplicationInformation from '../../components/application-info/application-info.component';

const NewApplication: React.FC = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema('new-application')),
    mode: 'onTouched',
  });

  const { user } = useAuth();
  const { canApply, createApplication, createApplicationResult } = useContext(ApplicationContext);
  const { currentBatch, currentBatchStatus } = useContext(BatchContext);

  const navigate = useNavigate();

  const submitNewApplicationForm = async (data: any) => {
    if (user && currentBatch) await createApplication(fromFormDataToNewApplicationData(data, currentBatch.id, user.id));
  };

  const success =
    createApplicationResult.called &&
    !createApplicationResult.loading &&
    !createApplicationResult.error &&
    createApplicationResult.data;
  const error = createApplicationResult.called && !createApplicationResult.loading && createApplicationResult.error;

  // navigate to dashboard if application was successful
  useEffect(() => {
    if (success) {
      createApplicationResult.reset();
      navigate(`/dashboard?apply-success=1`);
    }
  }, [createApplicationResult, success, navigate]);

  return (
    <>
      {error && <Alert variant="danger">Something went wrong while applying. Please try again.</Alert>}
      <h1 className="pb-4">Your application</h1>
      <p>
        Please carefully complete the required information in this page. We will use the content of this form along with
        your profile information to assess your application.
      </p>
      {currentBatch && currentBatchStatus === 'open' && canApply ? (
        <>
          <ApplicationInformation batch={currentBatch} />
          <ApplicationData {...{ register, control, errors, setValue, watch }} />
          <CustomButton onClick={handleSubmit(submitNewApplicationForm)}>Apply</CustomButton>
        </>
      ) : (
        <>
          <p>Unfortunately you cannot apply at this moment.</p>
          <ButtonLink href="/dashboard">Back to Dashboard</ButtonLink>
        </>
      )}
    </>
  );
};

export default NewApplication;
