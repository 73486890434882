import React, { ForwardedRef } from 'react';
import ReactSelect, { ActionMeta, OnChangeValue, Props, StylesConfig } from 'react-select';
import variables from '../../../variables.module.scss';
import SelectOption from './SelectOption';

const styles: StylesConfig<SelectOption, true> = {
  multiValue: (base, state) => {
    return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 } : base;
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: 'none' } : base;
  },
};

type CustomProps = Omit<Omit<Omit<Props<SelectOption>, 'options'>, 'onChange'>, 'styles'> & {
  error?: string;
  disabled?: boolean;
  options: SelectOption[];
  onChange: any;
};

const CustomReactSelect = React.forwardRef(
  ({ error, disabled, options, ...otherProps }: CustomProps, ref: ForwardedRef<any>) => {
    const onChange = (newValue: OnChangeValue<SelectOption, true>, actionMeta: ActionMeta<SelectOption>) => {
      switch (actionMeta.action) {
        case 'remove-value':
        case 'pop-value':
          if (actionMeta.removedValue.isFixed) {
            return;
          }
          break;
        case 'clear':
          newValue = options.filter((v) => v.isFixed);
          break;
      }
      otherProps.onChange(newValue, actionMeta);
    };

    return (
      <ReactSelect
        isDisabled={disabled}
        className={error ? 'is-invalid' : ''}
        styles={{
          ...styles,
          control: (baseStyles) => ({
            ...baseStyles,
            ...(error ? { borderColor: variables.colorDanger } : {}),
          }),
        } as any}
        theme={(theme) => ({
          ...theme,
          borderRadius: variables.borderRadius as any,
          colors: {
            ...theme.colors,
            primary25: variables.colorPrimary25,
            primary50: variables.colorPrimary50,
            primary75: variables.colorPrimary75,
            primary: variables.colorPrimary,
            danger: variables.colorDanger,
          },
        })}
        options={options}
        {...otherProps}
        onChange={onChange as any}
        ref={ref}
      />
    );
  },
);

export { CustomReactSelect };
