import { Navbar } from 'react-bootstrap';
import styled from 'styled-components';

const StyledNavbar = styled(Navbar)`
  z-index: 10;
  .navbar-toggler {
    border: none;
  }
`;

export { StyledNavbar };
