import './App.scss';
import { useRoutes } from 'react-router-dom';
import Dashboard from './routes/dashboard/dashboard.component';
import Home from './routes/home/home.component';
import Navigation from './routes/navigation/navigation.component';
import SignIn from './routes/auth/sign-in/sign-in.component';
import SignUp from './routes/auth/sign-up/sign-up.component';
import SignOut from './routes/auth/sign-out/sign-out.component';
import SignUpPage1 from './routes/auth/sign-up/sign-up-page-1.component';
import SignUpPage2 from './routes/auth/sign-up/sign-up-page-2.component';
import SignUpPage3 from './routes/auth/sign-up/sign-up-page-3.component';
import SignUpFinalize from './routes/auth/sign-up/sign-up-finalize.component';
import Profile from './routes/profile/profile.component';
import PasswordForgot from './routes/auth/password-forgot/password-forgot.component';
import PasswordReset from './routes/auth/password-reset/password-reset.component';
import Teams from './routes/teams/teams-component';
import NewTeam from './routes/new-team/new-team.component';
import UpdateTeam from './routes/team/update-team.component';
import NewApplication from './routes/apply/new-application.component';
import PrivateRoute from './components/private-route/private-route.component';
import PublicRoute from './components/public-route/public-route.component';
import Application from './routes/application/application.component';

function App() {
  const routes = useRoutes([
    {
      path: '/',
      element: <Navigation />,
      children: [
        { path: '', element: <Home /> },
        { path: 'dashboard', element: <PrivateRoute element={<Dashboard />} /> },
        { path: 'profile', element: <PrivateRoute element={<Profile />} /> },
        {
          path: 'teams',
          children: [
            { path: '', element: <PrivateRoute element={<Teams />} /> },
            { path: 'new', element: <PrivateRoute element={<NewTeam />} /> },
            { path: ':slug', element: <PrivateRoute element={<UpdateTeam />} /> },
          ],
        },
        { path: 'apply', element: <PrivateRoute element={<NewApplication />} /> },
        { path: 'applications/:id', element: <PrivateRoute element={<Application />} /> },
        {
          path: 'auth',
          children: [
            { path: 'password-forgot', element: <PublicRoute element={<PasswordForgot />} /> },
            { path: 'password-reset', element: <PublicRoute element={<PasswordReset />} /> },
            { path: 'sign-in', element: <PublicRoute element={<SignIn />} /> },
            { path: 'sign-out', element: <PrivateRoute element={<SignOut />} /> },
            {
              path: 'sign-up',
              element: <PublicRoute element={<SignUp />} />,
              children: [
                { path: '1', element: <PublicRoute element={<SignUpPage1 />} /> },
                { path: '2', element: <PublicRoute element={<SignUpPage2 />} /> },
                { path: '3', element: <PublicRoute element={<SignUpPage3 />} /> },
                { path: 'fin', element: <PublicRoute element={<SignUpFinalize />} /> },
              ],
            },
          ],
        },
      ],
    },
  ]);

  return routes;
}

export default App;
