import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import MultistepFormButtons from '../../../components/multistep-form-buttons/multistep-form-buttons.component';
import useMultistepForm from '../../../hooks/useMultistepForm';
import { SignUpForm } from './sign-up.component';
import PrivacyAndReach from '../../../forms/privacy-and-reach/privacy-and-reach.component';
import schema from '../../../forms/privacy-and-reach/privacy-and-reach.schema';
import { fromFormDataToSignUpData } from '../../../utils/gql.utils';
import { useAuth } from '../../../contexts/auth/auth.context';


function SignUpPage3() {
  const { next, prev, currentStep, steps, currentStepData, formData } = useMultistepForm(SignUpForm);
  const {signUp} = useAuth()
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema('sign-up')), mode: 'onTouched', defaultValues: currentStepData });

  const submitSignUpForm = (data: any)=>{
    // TODO: this is a hack for making the multistep form work
    // a better and more flexible design for the form should be defined
    const newFormData = [
      formData[0],
      formData[1],
      data
    ]
    // sign up user
    signUp(fromFormDataToSignUpData(newFormData));
    // display next form page
    next(data)
  }

  return (
    <>
      <PrivacyAndReach {...{ errors, control, register }} variant='sign-up' />
      <MultistepFormButtons next={handleSubmit(submitSignUpForm)} prev={handleSubmit(prev)} {...{ currentStep, steps }} />
    </>
  );
}

export default SignUpPage3;
