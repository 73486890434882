const industries = [
  {
    label: 'Advertising',
    value: 'advertising',
  },
  {
    label: 'Agritech',
    value: 'agritech',
  },
  {
    label: 'Automotive',
    value: 'automotive',
  },
  {
    label: 'Beauty & Lifestyle',
    value: 'beauty_and_lifestyle',
  },
  {
    label: 'BioTech',
    value: 'biotech',
  },
  {
    label: 'Blockchain',
    value: 'blockchain',
  },
  {
    label: 'Community',
    value: 'community',
  },
  {
    label: 'ConsumerTech',
    value: 'consumer_tech',
  },
  {
    label: 'Ecommerce',
    value: 'ecommerce',
  },
  {
    label: 'Education',
    value: 'education',
  },
  {
    label: 'Enterprise Software',
    value: 'enterprise_software',
  },
  {
    label: 'Entertainment',
    value: 'entertainment',
  },
  {
    label: 'Events',
    value: 'events',
  },
  {
    label: 'Fashion',
    value: 'fashion',
  },
  {
    label: 'Finance',
    value: 'finance',
  },
  {
    label: 'Food',
    value: 'food',
  },
  {
    label: 'HR Tech',
    value: 'hr_tech',
  },
  {
    label: 'Healthcare',
    value: 'healthcare',
  },
  {
    label: 'Impact',
    value: 'impact',
  },
  {
    label: 'Mobility',
    value: 'mobility',
  },
  {
    label: 'Music',
    value: 'music',
  },
  {
    label: 'Physical Security',
    value: 'physical_security',
  },
  {
    label: 'PropTech',
    value: 'prop_tech',
  },
  {
    label: 'Real Estate',
    value: 'real_estate',
  },
  {
    label: 'Retail',
    value: 'retail',
  },
  {
    label: 'SpaceTech',
    value: 'space_tech',
  },
  {
    label: 'SportTech',
    value: 'sport_tech',
  },
  {
    label: 'Travel',
    value: 'travel',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export default industries