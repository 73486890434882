import Checkbox from '../checkbox/checkbox.component';

/**
 *
 * A controlled input group for skills.
 * Each skills is represented by a Checkbox.
 *
 */
type Props = {
  name: string;
  fields: {
    name: string;
    label: string;
    initial?: boolean;
  }[];
  register: any;
};

/**
 * 
 * A skill group. Optionally, a default value for the skills can be supplied.
 * The Skill Group is modeled as a group of uncontrolled checkboxes.
 */
const SkillGroup: React.FC<Props> = ({ name, fields, register }) => {
  return (
    <div className="mb-3">
      <h5 className="mb-2">{name}</h5>
      {fields.map((e) => (
        <Checkbox key={e.name} name={e.name} label={e.label} error="" {...register(e.name)} />
      ))}
    </div>
  );
};

export default SkillGroup;
