import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import CustomFooter from '../../components/footer/footer.component';
import CustomNavbar from '../../components/navbar/navbar.component';
import { BatchContext } from '../../contexts/batch.context';
import Banner from '../../components/banner/banner.component';
import { NavigationContainer, PageContainer, AppContainer } from './navigation.styles';
import { toReadableString } from '../../utils/date.utils';

const Navigation: React.FC = () => {
  // if any batch is starting, create an announcement banner
  const { currentBatch, currentBatchStatus } = useContext(BatchContext);
  return (
    <AppContainer>
      {currentBatch && (
        <Banner
          variant="primary"
          data={{
            text:
              currentBatchStatus === 'open'
                ? `Applications for ${currentBatch.name} are open! You can apply until ${toReadableString(
                    new Date(currentBatch.application_closing),
                  )}`
                : `Applications for ${currentBatch.name} open on ${toReadableString(
                    new Date(currentBatch.application_opening),
                  )}`,
          }}
        />
      )}
      <NavigationContainer>
        <CustomNavbar />
        <PageContainer>
          <Outlet />
        </PageContainer>
      </NavigationContainer>
      <CustomFooter />
    </AppContainer>
  );
};

export default Navigation;
