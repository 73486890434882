import { Container } from 'react-bootstrap';
import styled from 'styled-components';

const StyledFooter = styled(Container)`
  position: absolute;
  bottom: 0px;
  padding-bottom: 20px;
  text-align: center;
`;

export { StyledFooter };
