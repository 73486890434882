import React from 'react';
import { Controller } from 'react-hook-form';

import FormItemContainer from '../form-item-container/form-item-container.component';
import { CustomReactSelect } from './custom-react-select.component';
import FormInputProps from '../../../types/FormInputProps';
import SelectOption from './SelectOption';

type Props = FormInputProps<{
  text?: string;
  multi?: boolean;
  control: any;
  options: SelectOption[];
  placeholder?: string;
  onInputChange?: any;
  noOptionsMessage?: () => string;
}>;

/**
 * A react-select wrapper compatible with react-hook-form.
 */
const Select: React.FC<Props> = ({
  name,
  required,
  label,
  error,
  text,
  multi,
  control,
  options,
  placeholder,
  disabled,
  ...otherProps
}) => (
  <FormItemContainer {...{ label, required, error, text }}>
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <CustomReactSelect
          isMulti={multi}
          isClearable={false}
          {...field}
          {...{ required, options, placeholder, error, disabled }}
          {...otherProps}
        />
      )}
    />
  </FormItemContainer>
);

export default Select;
