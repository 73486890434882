import { ValidationError, array, object, string } from 'yup';
import FormSchemaBuilder from '../../types/form-schema-builder.type';

const schema: FormSchemaBuilder<'new-application'> = (variant) =>
  object({
    type: string().required(),
    individualVertical: array(object()).test({
      name: 'candidateTeamTest',
      test: function(value) {
        const conditionFieldValue = this.parent.type;
        if (conditionFieldValue === 'individual' && !value) {
          throw new ValidationError('Individual Vertical is required', value, 'individualVertical');
        }
        return true;
      }
    }),
    individualOtherVertical: string().optional(),
    candidateTeam: object().test({
      name: 'candidateTeamTest',
      test: function(value) {
        const conditionFieldValue = this.parent.type;
        if (conditionFieldValue === 'team' && !value) {
          throw new ValidationError('Candidate Team is required', value, 'candidateTeam');
        }
        return true;
      }
    }),
    motivation: string().required(),
    expectation: string().required(),
  });

export default schema;
