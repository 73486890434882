import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth/auth.context';

type PrivateRouteProps = {
  element: React.ReactNode;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <>{element}</> : <Navigate to="/auth/sign-in" replace />;
};
export default PrivateRoute;
