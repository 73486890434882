import { useForm } from 'react-hook-form';
import GeneralInformation from '../../forms/general-information/general-information.component';
import Skills from '../../forms/skills/skills.component';
import PrivacyAndReach from '../../forms/privacy-and-reach/privacy-and-reach.component';
import { useAuth } from '../../contexts/auth/auth.context';
import howDidYouFindUs from '../../data/how-did-you-find-us';
import generalInformationSchema from '../../forms/general-information/general-information.schema';
import skillsSchema from '../../forms/skills/skills.schema';
import privacyAndReachSchema from '../../forms/privacy-and-reach/privacy-and-reach.schema';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomButton from '../../components/custom-button/custom-button.component';
import { Alert } from 'react-bootstrap';
import { fromFormDataToUpdateProfileData } from '../../utils/gql.utils';
import { useEffect } from 'react';

const Profile: React.FC = () => {
  const { user, updateProfile, updateProfileResult } = useAuth();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      generalInformationSchema('profile').concat(skillsSchema('profile')).concat(privacyAndReachSchema('profile')),
    ),
    defaultValues: user
      ? {
          username: user.username,
          email: user.email,
          firstName: user.first_name,
          lastName: user.last_name,
          linkedin: user.linkedin_url,
          website: user.website_url,
          associations: user.associations
            ? user.associations.map((e) => (typeof e == 'string' ? e : { value: e.id, label: e.name }))
            : [],
          otherAssociations: user.other_associations,
          skills: user.skills,
          moreInfo: user.more_info,
          howDidYouFindUs: howDidYouFindUs.find((e) => e.value === user.how_did_you_find_us),
          privacyPolicy: user.privacy_policy,
        }
      : {},
  });

  useEffect(() => {
    updateProfileResult.reset();
  }, []);

  const onClickHandler = async (data: any) => {
    if (user) {
      await updateProfile(user?.id, fromFormDataToUpdateProfileData(data));
    }
  };

  const success =
    updateProfileResult.called &&
    !updateProfileResult.loading &&
    !updateProfileResult.error &&
    updateProfileResult.data;
  const error = updateProfileResult.called && !updateProfileResult.loading && updateProfileResult.error;
  return (
    <>
      {success && <Alert variant="success">Profile updated successfully.</Alert>}
      {error && <Alert variant="danger">Something went wrong while updating your profile.</Alert>}
      <h1>Profile</h1>
      <p>
        Here you can edit your personal information. Please make sure to keep the data in this form update, since they
        will be part of your application to Astra.
      </p>
      <GeneralInformation errors={errors} control={control} register={register} variant="profile"></GeneralInformation>
      <Skills errors={errors} control={control} register={register}></Skills>
      <PrivacyAndReach errors={errors} control={control} register={register} variant="profile"></PrivacyAndReach>
      <CustomButton variant="primary" type="button" onClick={handleSubmit(onClickHandler)}>
        Update
      </CustomButton>
    </>
  );
};

export default Profile;
