function removeTypename(obj) {
  if (!obj || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => removeTypename(item));
  }

  const newObj = {};
  for (const key in obj) {
    if (key !== '__typename') {
      newObj[key] = removeTypename(obj[key]);
    }
  }

  return newObj;
}

export default removeTypename;
