import { DocumentNode, MutationHookOptions, OperationVariables, TypedDocumentNode, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import removeTypename from '../graphql/remove-typename';

type UseCustomMutationType = (
  mutation: DocumentNode | TypedDocumentNode<any, OperationVariables>,
  options?: MutationHookOptions<any> | undefined,
) => any;

const filter = (res: any) => ({
  ...res,
  data: removeTypename(res.data),
});

const useCustomMutation: UseCustomMutationType = (mutation, options) => {
  const [mutationFunction, mutationResult] = useMutation(mutation, options);
  const { data, loading, called, error } = mutationResult;

  const [state, setState] = useState(filter(mutationResult));

  useEffect(() => {
    setState(filter(mutationResult));
  }, [data, loading, called, error ]);
  return [
    async (mutationOptions: any) => {
      const res = await mutationFunction(mutationOptions);
      return filter(res);
    },
    state,
  ];
};

export default useCustomMutation;
