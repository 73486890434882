import { array, boolean, number, object, string } from 'yup';
import FormSchemaBuilder from '../../types/form-schema-builder.type';
import { skillCategories } from '../../data/skills';

const schema: FormSchemaBuilder<'new-team' | 'update-team'> = (variant) =>
  object({
    name: string().required(),
    members: array(object()).optional().default([]),
    industry: object().required(),
    otherVertical: string().optional(),
    teamAdditionalInfo: string().required(),
    problemDescription: string().required(),
    solutionDescription: string().required(),
    lookingForMembers: boolean().optional(),
    searchingSkills: object({
      ...skillCategories.reduce((acc, category) => {
        return {
          ...acc,
          [category.id]: object(
            category.skills.reduce((acc, skill) => {
              return { ...acc, [skill.id]: number().optional().max(10).min(0) };
            }, {}),
          ),
        };
      }, {}),
    }),
  });

export default schema;
