import Checkbox from '../../components/fields/checkbox/checkbox.component';
import Select from '../../components/fields/select/select.component';
import FormSection from '../../components/form-section/form-section.component';
import howDidYouFindUs from '../../data/how-did-you-find-us';

type Props = {
  errors: any;
  control: any;
  register: any;
  // form variant: defines the context where the form is used. this allows better re-usage of the component
  variant: 'sign-up' | 'profile';
};

const PrivacyAndReach: React.FC<Props> = ({ errors, control, register, variant }) => {
  return (
    <FormSection title={'Privacy and Reach'}>
      <Select
        name="howDidYouFindUs"
        label="How did you find us?"
        required
        error={errors.howDidYouFindUs && `${errors.howDidYouFindUs?.message}`}
        options={howDidYouFindUs}
        control={control}
      />
      <Checkbox
        label={
          <span>
            I have read and accepted the{' '}
            <a href="https://www.iubenda.com/privacy-policy/63271577/full-legal">Privacy Policy</a>.
          </span>
        }
        disabled = {variant === 'profile'}
        required
        error={errors.privacyPolicy && `${errors.privacyPolicy?.message}`}
        {...register('privacyPolicy', { required: true })}
      />
    </FormSection>
  );
};


export default PrivacyAndReach