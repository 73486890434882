import { Container, Nav, Navbar, Offcanvas } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/auth/auth.context';
import { StyledNavbar } from './navbar.styles';

function CustomNavbar() {
  const { isAuthenticated } = useAuth();
  const routes = isAuthenticated
    ? [
        { label: 'Dashboard', href: '/dashboard' },
        { label: 'Teams', href: '/teams' },
        { label: 'Profile', href: '/profile' },
        { label: 'Sign out', href: '/auth/sign-out' },
      ]
    : [
        { label: 'Home', href: '/' },
        { label: 'Sign in', href: '/auth/sign-in' },
      ];
  const expand = 'md';
  return (
    <StyledNavbar key={expand} bg="white" expand={expand}>
      <Container fluid>
        <Navbar.Brand as={Link} to="/">
          <img alt="" src="/logo.png" height="30" className="d-inline-block align-top" />
          <span className="mx-3" style={{ fontSize: '85%' }}>
            Application Portal
          </span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-${expand}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>Menu</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              {routes.map((e) => (
                <Nav.Link key={e.href} as={Link} to={e.href}>
                  {e.label}
                </Nav.Link>
              ))}
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </StyledNavbar>
  );
}

export default CustomNavbar;
