import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth/auth.context';

type PublicRouteProps = {
  element: React.ReactNode;
};

const PublicRoute: React.FC<PublicRouteProps> = ({ element }) => {
  const { isAuthenticated } = useAuth();
  return !isAuthenticated ? <>{element}</> : <Navigate to="/dashboard" replace />;
};
export default PublicRoute;
