import React from "react";
import { StyledButton } from "./custom-button.styles";
import { ButtonProps } from "react-bootstrap";
import ComponentProps from "../../types/ComponentProps";

type Props = ComponentProps<
  ButtonProps & {
    children?: string;
    variant?: string;
  }
>;

/**
 * Custom styled button.
 */
const CustomButton: React.FC<Props> = ({ children, variant, ...props }) => (
  <StyledButton {...{ variant }} {...props}>
    {children}
  </StyledButton>
);

export default CustomButton;
export type { Props as ButtonProps };
