import { ApolloError, gql } from '@apollo/client';
import { createContext, useEffect, useState } from 'react';
import { Batch } from '../types/payload-types.type';
import useCustomQuery from '../hooks/useCustomQuery';

const BatchContext = createContext<{
  batches: Batch[] | null;
  currentBatch: Batch | null;
  currentBatchStatus: 'open' | 'closed' | null;
  loading: boolean;
  error?: ApolloError;
}>({ batches: null, currentBatch: null, currentBatchStatus: null, loading: true, error: undefined });

const BATCHES = gql`
  query getBatches($nowString: DateTime!) {
    Batches(
      limit: 1
      page: 1
      where: {
        OR: [
          { application_opening: { greater_than_equal: $nowString } }
          { application_closing: { greater_than_equal: $nowString } }
        ]
      }
    ) {
      docs {
        id
        name
        description
        application_opening
        application_closing
      }
    }
  }
`;

function BatchProvider({ children }: any) {
  const [now] = useState(new Date());
  const [batches, setBatches] = useState(null);
  const { queryLoading, error, data } = useCustomQuery(BATCHES, { variables: { nowString: now.toISOString() } });
  const [loading, setLoading] = useState(true);
  const [currentBatch, setCurrentBatch] = useState(null);
  const [currentBatchStatus, setCurrentBatchStatus] = useState<'open' | 'closed' | null>(null);

  useEffect(() => {
    if (data) {
      const {
        Batches: { docs },
      } = data;
      setBatches(docs);
      // if a batch is present
      if (docs.length > 0) {
        // set as current batch
        setCurrentBatch(docs[0]);
        // determine whether it's status at the moment
        const application_opening = new Date(docs[0].application_opening);
        const application_closing = new Date(docs[0].application_closing);
        if (application_opening > now) setCurrentBatchStatus('closed');
        else if (application_closing > now) setCurrentBatchStatus('open');
      }
      if (!queryLoading) setLoading(false);
    }
  }, [data, queryLoading, now]);

  const value = {
    batches,
    currentBatch,
    currentBatchStatus,
    loading,
    error,
  };
  return <BatchContext.Provider value={value}>{children}</BatchContext.Provider>;
}

export default BatchProvider;
export { BatchContext };
