import { ApolloError, gql } from '@apollo/client';
import { createContext, useEffect, useState } from 'react';
import { useAuth } from './auth/auth.context';
import useCustomLazyQuery from '../hooks/useCustomLazyQuery';
import useCustomMutation from '../hooks/useCustomMutation';
import { Team } from '../types/payload-types.type';

const TeamContext = createContext<{
  teams: Team[] | null;
  ownedTeams: Team[] | null;
  sharedTeams: Team[] | null;
  getTeamsLoading: boolean;
  getTeamsError: ApolloError | undefined;
  createTeam: any;
  createTeamResult: any;
  updateTeam: any;
  updateTeamResult: any;
}>({} as any);

const TEAMS = gql`
  query getTeams {
    Teams(limit: 50, page: 1) {
      docs {
        id
        name
        slug
        owner {
          id
          username
          first_name
          last_name
        }
        members {
          member {
            id
            username
            first_name
            last_name
          }
        }
        industry
        other_vertical
        team_additional_info
        problem_description
        solution_description
        looking_for_members
        searching_skills {
          business {
            accounting
            fundraising
            investor_relations
            pitching
            planning_and_strategy
            product_management
          }
          computer_science {
            ai_and_machine_learning
            backend_development
            blockchain
            cybersecurity
            frontend_development
            embedded_development
            other
            quantum_computing
          }
          engineering {
            aerospace
            biomedical
            biotechnology
            electronic
            mechanical
            nuclear
          }
          content_creation {
            audio
            digital_art
            photography
            storytelling
            text
            videomaking
          }
          marketing_and_public_relations {
            seo
            social_media
          }
          miscellanea {
            business_analytics
            chemistry
            law
            ux_and_ui
          }
        }
      }
    }
  }
`;

const CREATE_TEAM = gql`
  mutation createTeam($data: mutationTeamInput!) {
    createTeam(data: $data) {
      id
      name
      slug
      owner {
        id
        username
        first_name
        last_name
      }
      members {
        member {
          id
          username
          first_name
          last_name
        }
      }
      industry
      other_vertical
      team_additional_info
      problem_description
      solution_description
      looking_for_members
      searching_skills {
        business {
          accounting
          fundraising
          investor_relations
          pitching
          planning_and_strategy
          product_management
        }
        computer_science {
          ai_and_machine_learning
          backend_development
          blockchain
          cybersecurity
          frontend_development
          embedded_development
          other
          quantum_computing
        }
        engineering {
          aerospace
          biomedical
          biotechnology
          electronic
          mechanical
          nuclear
        }
        content_creation {
          audio
          digital_art
          photography
          storytelling
          text
          videomaking
        }
        marketing_and_public_relations {
          seo
          social_media
        }
        miscellanea {
          business_analytics
          chemistry
          law
          ux_and_ui
        }
      }
    }
  }
`;

const UPDATE_TEAM = gql`
  mutation updateTeam($id: String!, $data: mutationTeamUpdateInput!) {
    updateTeam(id: $id, data: $data, draft: false) {
      id
      name
      slug
      owner {
        id
        username
        first_name
        last_name
      }
      members {
        member {
          id
          username
          first_name
          last_name
        }
      }
      industry
      other_vertical
      team_additional_info
      problem_description
      solution_description
      looking_for_members
      searching_skills {
        business {
          accounting
          fundraising
          investor_relations
          pitching
          planning_and_strategy
          product_management
        }
        computer_science {
          ai_and_machine_learning
          backend_development
          blockchain
          cybersecurity
          frontend_development
          embedded_development
          other
          quantum_computing
        }
        engineering {
          aerospace
          biomedical
          biotechnology
          electronic
          mechanical
          nuclear
        }
        content_creation {
          audio
          digital_art
          photography
          storytelling
          text
          videomaking
        }
        marketing_and_public_relations {
          seo
          social_media
        }
        miscellanea {
          business_analytics
          chemistry
          law
          ux_and_ui
        }
      }
    }
  }
`;

function TeamProvider({ children }: any) {
  const { user } = useAuth();
  const [teams, setTeams] = useState<Team[] | null>(null);
  const [getTeams, { loading, getTeamsError, data }] = useCustomLazyQuery(TEAMS, {fetchPolicy:'network-only'});
  const [createTeamMutation, createTeamResult] = useCustomMutation(CREATE_TEAM);
  const [updateTeamMutation, updateTeamResult] = useCustomMutation(UPDATE_TEAM);
  const [getTeamsLoading, setGetTeamsLoading] = useState(true);
  const [ownedTeams, setOwnedTeams] = useState<Team[] | null>(null);
  const [sharedTeams, setSharedTeams] = useState<Team[] | null>(null);

  useEffect(() => {
    setOwnedTeams(
      teams ? teams.filter((e) => (typeof e.owner === 'string' ? e.owner : e.owner.id) === user?.id) : null,
    );
    setSharedTeams(
      teams ? teams.filter((e) => (typeof e.owner === 'string' ? e.owner : e.owner.id) !== user?.id) : null,
    );
  }, [teams]);

  useEffect(() => {
    setGetTeamsLoading(loading || teams === null);
  }, [loading, teams]);

  const createTeam = async (data: any) => {
    if (user) {
      await createTeamMutation({ variables: { data } });
      getTeams({ variables: { owner: user.id } });
    }
  };

  const updateTeam = async (id: string, data: any) => {
    if (user) {
      await updateTeamMutation({ variables: { id, data } });
      getTeams({ variables: { owner: user.id } });
    }
  };

  useEffect(() => {
    if (user) getTeams({ variables: { owner: user.id } });
  }, [user, getTeams]);

  useEffect(() => {
    if (data) {
      const {
        Teams: { docs },
      } = data;
      setTeams(docs);
    }
  }, [data]);

  const value = { teams, ownedTeams, sharedTeams, getTeamsLoading, getTeamsError, createTeam, createTeamResult, updateTeam, updateTeamResult };
  return <TeamContext.Provider value={value}>{children}</TeamContext.Provider>;
}

export default TeamProvider;
export { TeamContext };
