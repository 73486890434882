import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import TeamData from '../../forms/team-data/team-data.component';
import schema from '../../forms/team-data/team-data.schema';
import CustomButton from '../../components/custom-button/custom-button.component';
import { TeamContext } from '../../contexts/team.context';
import { fromFormDataToNewTeamData } from '../../utils/gql.utils';
import { useAuth } from '../../contexts/auth/auth.context';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

const NewTeam: React.FC = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema('new-team')),
    mode: 'onTouched',
  });

  const { user } = useAuth();
  const { createTeam, createTeamResult } = useContext(TeamContext);
  const navigate = useNavigate();

  const submitNewTeamForm = async (data: any) => {
    if (user) await createTeam(fromFormDataToNewTeamData(data, user.id));
  };


  const success =
  createTeamResult.called &&
    !createTeamResult.loading &&
    !createTeamResult.error &&
    createTeamResult.data;
  const error = createTeamResult.called && !createTeamResult.loading && createTeamResult.error;

  // navigate to dashboard if login was successful
  useEffect(() => {
    if (success) {
      createTeamResult.reset();
      navigate(`/teams?create=${createTeamResult.data.createTeam.slug}`);
    }
  }, [createTeamResult?.called, createTeamResult?.loading, createTeamResult?.error, navigate]);

  
  return (
    <>
      {error && <Alert variant='danger'>Something went wrong while creating the team. Please try again.</Alert>}
      <h1 className="pb-4">New team</h1>
      <TeamData {...{ register, control, errors, setValue }} variant="new-team" />
      <CustomButton onClick={handleSubmit(submitNewTeamForm)}>Create</CustomButton>
    </>
  );
};

export default NewTeam;
