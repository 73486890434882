const associationsMap = (e) => e.value;

function fromFormDataToSignUpData(formData: any) {
  return {
    username: formData[0].username,
    first_name: formData[0].firstName,
    last_name: formData[0].lastName,
    linkedin_url: formData[0].linkedin,
    website_url: formData[0].website,
    skills: formData[1].skills,
    associations: formData[0].associations.map(associationsMap),
    other_associations: formData[0].otherAssociations,
    more_info: formData[1].moreInfo,
    how_did_you_find_us: formData[2].howDidYouFindUs.value,
    privacy_policy: formData[2].privacyPolicy,
    email: formData[0].email,
    password: formData[0].password,
  };
}

function fromFormDataToUpdateProfileData(formData: any) {
  return {
    first_name: formData.firstName,
    last_name: formData.lastName,
    linkedin_url: formData.linkedin,
    website_url: formData.website,
    skills: formData.skills,
    associations: formData.associations.map(associationsMap),
    other_associations: formData.otherAssociations,
    more_info: formData.moreInfo,
    email: formData.email,
    // if password is empty, don't send
    ...(formData.password === '' ? {} : { password: formData.password }),
  };
}

function fromFormDataToNewTeamData(formData: any, ownerId: string) {
  return {
    name: formData.name,
    owner: ownerId,
    members: [{ member: ownerId }, ...formData.members.map((e) => ({ member: e.value }))],
    industry: associationsMap(formData.industry),
    other_vertical: formData.otherVertical,
    team_additional_info: formData.teamAdditionalInfo,
    problem_description: formData.problemDescription,
    solution_description: formData.solutionDescription,
    looking_for_members: formData.lookingForMembers,
    searching_skills: formData.searchingSkills,
  };
}

function fromFormDataToUpdateTeamData(formData: any, ownerId: string) {
  return {
    name: formData.name,
    owner: ownerId,
    // just the members array: already contains the owner id
    members: formData.members.map((e) => ({ member: e.value })),
    industry: associationsMap(formData.industry),
    other_vertical: formData.otherVertical,
    team_additional_info: formData.teamAdditionalInfo,
    problem_description: formData.problemDescription,
    solution_description: formData.solutionDescription,
    looking_for_members: formData.lookingForMembers,
    searching_skills: formData.searchingSkills,
  };
}

function fromFormDataToNewApplicationData(formData: any, batch_id: string, individual?: string) {
  return formData.type === 'individual'
    ? {
        type: formData.type,
        batch: batch_id,
        individual_vertical: associationsMap(formData.individualVertical),
        individual_other_vertical: formData.individualOtherVertical,
        motivation: formData.motivation,
        expectation: formData.expectation,
        candidate_individual: individual,
      }
    : {
        type: formData.type,
        batch: batch_id,
        candidate_team: associationsMap(formData.candidateTeam),
        motivation: formData.motivation,
        expectation: formData.expectation,
      };
}

/**
 * Remove null fields from the object
 * @param obj object to cleanup
 * @returns cleaned up object - without null fields
 */
function removeNullFields(obj) {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(removeNullFields);
  }

  const newObj = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = removeNullFields(obj[key]);
      if (value !== null) {
        newObj[key] = value;
      }
    }
  }
  return Object.keys(newObj).length > 0 ? newObj : null;
}

export {
  fromFormDataToSignUpData,
  fromFormDataToUpdateProfileData,
  fromFormDataToNewTeamData,
  removeNullFields,
  fromFormDataToUpdateTeamData,
  fromFormDataToNewApplicationData,
};
