import { Form } from 'react-bootstrap';
import FormInputProps from '../../../types/FormInputProps';
import React from 'react';

type Props = FormInputProps<{
  options: {
    value: string;
    label: string;
  }[];
}>;

const RadioGroup: React.FC<Props> = React.forwardRef<HTMLInputElement, Props>(({ options, name, ...otherProps }, ref) => (
  <div className="mb-3">
    {options.map((e) => (
      <Form.Check key={e.value} inline value={e.value} label={e.label} name={name} type="radio" {...otherProps} ref={ref}/>
    ))}
  </div>
));

export default RadioGroup;
