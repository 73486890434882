import React from 'react';
import { Form } from 'react-bootstrap';
import FormItemContainer from '../form-item-container/form-item-container.component';
import FormInputProps from '../../../types/FormInputProps';

type Props = FormInputProps<{
  type?: 'text' | 'email' | 'password';
  text?: string;
}>;

const TextInput: React.FC<Props> = React.forwardRef<HTMLInputElement, Props>(
  ({ name, required, type = 'text', label, error, text, onChange, onBlur, disabled, ...otherProps }, ref) => {
    return (
      <FormItemContainer {...{ label, required, error, text }}>
        <Form.Control {...{ name, type, required, ref, onChange, onBlur, disabled }} {...otherProps} isInvalid={!!error}/>
      </FormItemContainer>
    );
  },
);

export default TextInput;
