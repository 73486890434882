import { Alert, Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import TextInput from '../../../components/fields/text-input/text-input.component';
import { string, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth } from '../../../contexts/auth/auth.context';
import { useEffect } from 'react';

const signInSchema = object({
  email: string().required().email(),
  password: string().required(),
});

const SignIn: React.FC = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(signInSchema), mode: 'onTouched' });

  const { signIn, signInResult } = useAuth();

  const signInHandler = (data: any) => {
    // perform sign in
    signIn(data.email, data.password);
  };

  // navigate to dashboard if login was successful
  useEffect(() => {
    if (signInResult.called && !signInResult.loading && !signInResult.error) {
      signInResult.reset();
      navigate('/dashboard');
    }
  }, [signInResult, navigate]);

  return (
    <>
      <h1>Sign In</h1>
      {signInResult.error && <Alert variant="danger">The provided email or password is incorrect.</Alert>}
      <Form noValidate onSubmit={handleSubmit(signInHandler)}>
        <TextInput
          label="Email"
          type="email"
          required
          error={errors.email && `${errors.email?.message}`}
          {...register('email', { required: true })}
        />
        <TextInput
          label="Password"
          type="password"
          required
          error={errors.password && `${errors.password?.message}`}
          {...register('password', { required: true })}
        />
        <Button variant="primary" type="submit">
          Sign In
        </Button>
      </Form>
      <Link to="/auth/sign-up">Create an account</Link>
      <br />
      <Link to="/auth/password-forgot">Forgot your password?</Link>
    </>
  );
};

export default SignIn;
