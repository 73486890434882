import { Container } from 'react-bootstrap';
import styled from 'styled-components';

const NavigationContainer = styled.div`
  position: relative;
`;

const AppContainer = styled.div`
  position: relative;
  min-height: 100vh;
`;

const PageContainer = styled(Container)`
  max-width: 800px;
  padding-top: 50px;
  padding-bottom: 200px;
`;

export { NavigationContainer, PageContainer, AppContainer };
