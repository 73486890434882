import { useAuth } from '../../../contexts/auth/auth.context';
import useMultistepForm from '../../../hooks/useMultistepForm';
import { SignUpForm } from './sign-up.component';
import { Col, Row, Spinner } from 'react-bootstrap';
import ButtonLink from '../../../components/button-link/button-link.component';
import CustomButton from '../../../components/custom-button/custom-button.component';
import { useNavigate } from 'react-router-dom';

function SignUpFinalize() {
  const { reset } = useMultistepForm(SignUpForm);
  const navigate = useNavigate();
  const {
    signUpResult,
  } = useAuth();

  const { data, loading, error } = signUpResult
  const resetMutation = signUpResult.reset

  const resetForm = () => {
    reset(false);
    resetMutation()
    navigate('/auth/sign-up');
  };

  return (
    <>
      {loading && (
        <>
          <p>Signing you up...</p>
          <Spinner />
        </>
      )}
      {error && (
        <Row className="justify-content-xl-center my-5 w-100">
          <Col xs="auto" className="text-center">
            <h1>Unable to create your account</h1>
            <p>There was an error processing your request.</p>
            <CustomButton onClick={resetForm}>Try again</CustomButton>
          </Col>
        </Row>
      )}
      {data && (
        <Row className="justify-content-xl-center my-5 w-100">
          <Col xs="auto" className="text-center">
            <h1>Thank you for signing up!</h1>
            <p>You have successfully created your account.</p>
            <ButtonLink href="/auth/sign-in">Sign In</ButtonLink>
          </Col>
        </Row>
      )}
    </>
  );
}

export default SignUpFinalize;
