import { boolean, object } from 'yup';
import FormSchemaBuilder from '../../types/form-schema-builder.type';

const schema: FormSchemaBuilder<'sign-up' | 'profile'> = (variant) =>
  object({
    howDidYouFindUs: object().required(),
    ...(variant === 'sign-up'
      ? {
          privacyPolicy: boolean().required().isTrue('Please accept the Privacy Policy'),
        }
      : {}),
  });

export default schema;
