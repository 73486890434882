import Select from '../../components/fields/select/select.component';
import TextInput from '../../components/fields/text-input/text-input.component';
import FormSection from '../../components/form-section/form-section.component';
import industries from '../../data/industries';
import Textarea from '../../components/fields/textarea/textarea.component';
import RadioGroup from '../../components/fields/radio-group/radio-group.component';
import { useContext } from 'react';
import { TeamContext } from '../../contexts/team.context';

type Props = {
  errors: any;
  control: any;
  register: any;
  watch: any;
  disabled?: boolean;
};

const ApplicationData: React.FC<Props> = ({ errors, watch, control, register = false, disabled }) => {
  const applicationType = watch('type');
  const { ownedTeams, getTeamsLoading } = useContext(TeamContext);

  const teamsDropdown = ownedTeams ? ownedTeams.map((e) => ({ value: e.id, label: e.name })) : [];
  return (
    <>
      <FormSection title={'Application Type'}>
        <RadioGroup
          required
          error={errors.type && `${errors.type?.message}`}
          {...register('type', { required: true })}
          disabled={disabled}
          options={[
            { label: 'Individual: I do not have a business idea and want to join an existing team.', value: 'individual' },
            { label: 'Team: I/We have a business idea. (Select this even if you are an individual with a business idea)', value: 'team' },
          ]}
        />
      </FormSection>
      {applicationType === 'individual' && (
        <FormSection title={'Individual Additional Information'}>
          <Select
            name="individualVertical"
            text='Select the vertical of interest below. In case you cannot find your specific area of interest, please select the closest one here and indicate your specific sector under the selection "Other". '
            label="Select your main vertical of interest"
            required
            error={errors.individualVertical && `${errors.individualVertical?.message}`}
            multi={true}
            options={industries}
            control={control}
            disabled={disabled}
          />
          <TextInput
            name="individualOtherVertical"
            label="Other vertical of interest"
            type="text"
            error={errors.individualOtherVertical && `${errors.individualOtherVertical?.message}`}
            {...register('individualOtherVertical', { required: false })}
            disabled={disabled}
          />
        </FormSection>
      )}

      {applicationType === 'team' && (
        <FormSection title={'Team Additional Information'}>
          <Select
            name="candidateTeam"
            label="Candidate Team"
            required
            error={errors.candidateTeam && `${errors.candidateTeam?.message}`}
            multi={false}
            options={teamsDropdown}
            control={control}
            placeholder="Search by Team name..."
            noOptionsMessage={() => (getTeamsLoading ? 'Loading...' : 'No team match')}
            disabled={disabled}
            text="Select the team you want to apply with. Only the team owner can complete a team application. The application performed by the team owner will count as an application for each member of the team."
          />
        </FormSection>
      )}

      <FormSection title={'Motivation and Expectation'}>
        <Textarea
          label="What motivates you to apply to Astra?"
          required
          error={errors.motivation && `${errors.motivation?.message}`}
          {...register('motivation', { required: true })}
          disabled={disabled}
        />
        <Textarea
          label="What do you expect from the Astra incubation program?"
          required
          error={errors.expectation && `${errors.expectation?.message}`}
          {...register('expectation', { required: true })}
          disabled={disabled}
        />
      </FormSection>
    </>
  );
};

export default ApplicationData;
