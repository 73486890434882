import Select from '../../components/fields/select/select.component';
import TextInput from '../../components/fields/text-input/text-input.component';
import FormSection from '../../components/form-section/form-section.component';
import { useContext } from 'react';
import { AssociationContext } from '../../contexts/association.context';

type Props = {
  errors: any;
  control: any;
  register: any;
  // form variant: defines the context where the form is used. this allows better re-usage of the component
  variant: 'sign-up' | 'profile';
};

const GeneralInformation: React.FC<Props> = ({ errors, control, register, variant }) => {
  const { associations } = useContext(AssociationContext);

  const associationsDropdown = associations.map((e: any) => ({ value: e.id, label: e.name }));

  return (
    <FormSection title={'General Information'}>
      <TextInput
        label="Username"
        type="text"
        required = {variant === 'sign-up'}
        disabled = {variant === 'profile'}
        error={errors.username && `${errors.username?.message}`}
        {...register('username', { required: variant === 'sign-up' })}
      />
      <TextInput
        label="Email Address"
        type="email"
        required
        error={errors.email && `${errors.email?.message}`}
        {...register('email', { required: true })}
      />
      <TextInput
        label="Password"
        type="password"
        required = {variant === 'sign-up'}
        error={errors.password && `${errors.password?.message}`}
        {...register('password', { required: variant === 'sign-up' })}
      />
      <TextInput
        label="Repeat Password"
        type="password"
        required = {variant === 'sign-up'}
        error={errors.repeatPassword && `${errors.repeatPassword?.message}`}
        {...register('repeatPassword', { required: variant === 'sign-up' })}
      />
      <TextInput
        label="First Name"
        type="text"
        required
        error={errors.firstName && `${errors.firstName?.message}`}
        {...register('firstName', { required: true })}
      />
      <TextInput
        label="Last Name"
        type="text"
        required
        error={errors.lastName && `${errors.lastName?.message}`}
        {...register('lastName', { required: true })}
      />
      <TextInput
        label="LinkedIn URL"
        type="text"
        required
        error={errors.linkedin && `${errors.linkedin?.message}`}
        {...register('linkedin', { required: true })}
        text={`We value your time the same as you do. This is why we won't ask you to provide all your personal experience in a huge and time-consuming form. Instead, create a LinkedIn account if you haven't already, provide all the details there, then paste the URL of your profile here. Please make sure to provide as many details as possible, including your education and work experience. We'll use data from LinkedIn to accurately review your application and provide you a better experience.`}
      />
      <TextInput
        label="Website URL"
        type="text"
        error={errors.website && `${errors.website?.message}`}
        {...register('website', { required: false })}
        text={'If you have a website to showcase your work, please provide it here.'}
      />
      <Select
        name="associations"
        label="Are you part of any of these associations?"
        error={errors.associations && `${errors.associations?.message}`}
        multi={true}
        options={associationsDropdown}
        control={control}
      />
      <TextInput
        label="Are you part of any other association not listed above?"
        type="text"
        error={errors.otherAssociations && `${errors.otherAssociations?.message}`}
        {...register('otherAssociations', { required: false })}
      />
    </FormSection>
  );
};

export default GeneralInformation;
