import { Card } from 'react-bootstrap';
import ComponentProps from '../../types/ComponentProps';
import React from 'react';
import { TeamCardBoxBase, TeamCardBoxLight, TeamCardBoxPrimary } from './card-base.styles';
import { useNavigate } from 'react-router-dom';

type Props = ComponentProps<{
  title: string;
  subtitle: string;
  variant: 'primary' | 'light';
  url?: string;
}>;

const getTeamCardBox = (variant: 'primary' | 'light') => {
  switch (variant) {
    case 'primary':
      return TeamCardBoxPrimary;
    case 'light':
      return TeamCardBoxLight;
    default:
      return TeamCardBoxBase;
  }
};

const CardBase: React.FC<Props> = ({ title, subtitle, variant, url = '', ...props }) => {
  const Box = getTeamCardBox(variant);
  const navigate = useNavigate();

  const onBoxClickHandler = () => navigate(url);
  return (
    <Box onClick={onBoxClickHandler} {...props}>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{subtitle}</Card.Subtitle>
      </Card.Body>
    </Box>
  );
};

export default CardBase;
