import { Form } from 'react-bootstrap';

type Props = {
  label?: string | React.ReactNode;
  required?: boolean;
  text?: string;
  error?: string;
  children?: React.ReactNode;
};

const FormItemContainer: React.FC<Props> = ({ label, required, text, error, children }) => (
  <Form.Group className="mb-3">
    {label && (
      <Form.Label>
        {label}
        {required && <span>*</span>}
      </Form.Label>
    )}
    {children}
    <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    {text && <Form.Text>{text}</Form.Text>}
  </Form.Group>
);

export default FormItemContainer;
