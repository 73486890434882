import { createContext, useContext, useState } from 'react';

type StepData = Object;
type FormData = Object;
type MultistepForm = {
  Context: React.Context<any>;
  Provider: React.FC<any>;
};

const createMultistepForm = (steps: number) => {
  const Context = createContext({
    // the step the form is currently on
    currentStep: 0,
    // the full form data, as an array of step data
    formData: Array.from<StepData>([]),
    // data for the current form step
    currentStepData: {},
    // function to commit current step data and move to the previous step
    prev: (stepData: StepData) => {},
    // function to commit current step data and move to the next step
    next: (stepData: StepData) => {},
    // function to commit current step data and move to a determined step
    goTo: (step: number, stepData: StepData) => {},
    // number of steps in the form
    steps: 0,
    // function to prepare the form data for being submitted. it executes the callback function once the form data has been prepared.
    submit: (callback: (data: FormData) => void) => {},
    // whether the form has been completed or not
    complete: false,
    // function to reset the form status to not completed. optionally, erase the data collected as well
    reset: (erase: boolean) => {},
    commit: (stepData: any) =>{}
  });

  const Provider = ({ children }: any) => {
    const [formData, setFormData] = useState<StepData[]>([]);
    const [currentStep, setCurrentStep] = useState(1);
    const [complete, setComplete] = useState(false);

    const commit = (stepData: StepData) => {
      let newFormData = Array.from(formData);
      newFormData[currentStep - 1] = stepData;
      setFormData(newFormData);
    };

    const prev = (stepData: StepData) => {
      commit(stepData);
      setCurrentStep((i) => (i > 1 ? i - 1 : i));
    };

    const next = (stepData: StepData) => {
      commit(stepData);
      // if it is the last step, the form was completed
      if (currentStep === steps) setComplete(true);
      else setCurrentStep((i) => (i + 1));
    };

    const goTo = (step: number, stepData: StepData) => {
      commit(stepData);
      setCurrentStep(step);
    };

    const submit = (callback: (data: FormData) => void) => {
      callback(formData.reduce((acc, e) => ({ ...acc, ...e }), {}));
    };

    const reset = (erase: boolean)=>{
      if(erase) setFormData([])
      setCurrentStep(1)
      setComplete(false)
    }

    const value = {
      currentStep,
      formData,
      currentStepData: formData[currentStep - 1],
      prev,
      next,
      goTo,
      steps,
      submit,
      complete,
      reset,
      commit
    };

    return <Context.Provider value={value}>{children}</Context.Provider>;
  };

  return { Context, Provider };
};

const useMultistepForm = (form: MultistepForm) => useContext(form.Context);

export default useMultistepForm;
export { createMultistepForm };
