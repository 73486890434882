import React from 'react';
import { useAuth } from '../../contexts/auth/auth.context';
import { Spinner } from 'react-bootstrap';
import LoaderContainer from './auth-loader.styles';

type AuthLoaderProps = {
  children: React.ReactNode;
};

const AuthLoader: React.FC<AuthLoaderProps> = ({ children }) => {
  const { isLoading } = useAuth();

  if (isLoading) {
    return (
      <LoaderContainer>
        <Spinner />
      </LoaderContainer>
    );
  }

  return <>{children}</>;
};

export default AuthLoader;
