import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ComponentProps from '../../types/ComponentProps';
import { Application } from '../../types/payload-types.type';
import ApplicationCard from '../application-card/application-card.component';

type Props = ComponentProps<{
  applications: Application[];
  title: string;
  message?: string;
}>;

const ApplicationsSection: React.FC<Props> = ({ applications, title, className, message }) => {
  const applicationCards = applications.map((e) => (
    <Col key={e.id} className='px-3'>
      <ApplicationCard className="w-100" application={e} />
    </Col>
  ));
  return (
    <div className={className}>
      <h3 className='mb-4'>{title}</h3>
      {applicationCards.length ===0 && <Col xs={12}><p>{message}</p></Col>}
      <Row xs={1} sm={2} md={3} lg={3}>
        {applicationCards}
      </Row>
    </div>
  );
};

export default ApplicationsSection;
