import useMultistepForm, { createMultistepForm } from '../../../hooks/useMultistepForm';
import { Outlet, useNavigate } from 'react-router-dom';
import MultistepProgressBar from '../../../components/multistep-progress-bar/multistep-progress-bar.component';
import { useEffect } from 'react';

export const SignUpForm = createMultistepForm(3);

function SignUpNavigationUtil() {
  const { currentStep, complete } = useMultistepForm(SignUpForm);
  const navigate = useNavigate();

  useEffect(() => {
    if (!complete) navigate(`/auth/sign-up/${currentStep}`);
    else navigate(`/auth/sign-up/fin`);
  }, [currentStep, complete, navigate]);
  return <></>;
}

function SignUp() {

  return (
    <SignUpForm.Provider>
      <SignUpNavigationUtil />
      <h1>Sign Up</h1>
      <MultistepProgressBar form={SignUpForm} />
      <Outlet />
    </SignUpForm.Provider>
  );
}

export default SignUp;
