import FormSection from '../../components/form-section/form-section.component';
import { skillCategories } from '../../data/skills';
import SkillGroup from '../../components/fields/skillgroup/skillgroup.component';
import Textarea from '../../components/fields/textarea/textarea.component';

type Props = {
  errors: any;
  control: any;
  register: any;
};

const Skills: React.FC<Props> = ({ errors, control, register }) => {
  const skillGroups = skillCategories.map((c) => (
    <SkillGroup
      key={c.id}
      name={c.label}
      fields={c.skills.map((s) => ({ name: `skills.${c.id}.${s.id}`, label: s.label }))}
      register={register}
    />
  ));
  return (
    <FormSection title={'Skills'}>
      <p>
        Select the list of skills you consider yourself good at. You can choose multiple skills from each of the groups.
      </p>
      {skillGroups}
      <Textarea
        label="Can you give us additional information on yourself?"
        required
        error={errors.moreInfo && `${errors.moreInfo?.message}`}
        text="Feel free to write about your past working experience, your skills and how you are developing them, and what motivates you the most."
        {...register('moreInfo', { required: true })}
      />
    </FormSection>
  );
};

export default Skills;
