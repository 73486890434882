import ComponentProps from '../../types/ComponentProps';
import React from 'react';
import CardBase from '../card-base/card-base.component';
import { Application } from '../../types/payload-types.type';

type Props = ComponentProps<{
  application: Application;
}>;

const ApplicationCard: React.FC<Props> = ({ application, ...props }) => (
  <CardBase
    title={typeof application.batch === 'string' ? application.batch : application.batch.name}
    subtitle={application.type || ''}
    variant="light"
    url={`/applications/${application.id}`}
    {...props}
  />
);

export default ApplicationCard;
