import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import AssociationProvider from './contexts/association.context';
import { ApolloProvider } from '@apollo/client';
import { AuthProvider } from './contexts/auth/auth.context';
import ApolloAuthClient from './graphql/apollo-auth-client';
import TeamProvider from './contexts/team.context';
import BatchProvider from './contexts/batch.context';
import ApplicationProvider from './contexts/application.context';
import AuthLoader from './components/auth-loader/auth-loader.component';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<Index />);

function Index() {
  return (
    <React.StrictMode>
      <ApolloProvider client={ApolloAuthClient()}>
        <AssociationProvider>
          <BatchProvider>
            <AuthProvider>
              <TeamProvider>
                <ApplicationProvider>
                  <BrowserRouter>
                    <AuthLoader>
                      <App />
                    </AuthLoader>
                  </BrowserRouter>
                </ApplicationProvider>
              </TeamProvider>
            </AuthProvider>
          </BatchProvider>
        </AssociationProvider>
      </ApolloProvider>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
