import { ApolloError, gql } from '@apollo/client';
import { createContext, useContext, useEffect, useState } from 'react';
import { Application } from '../types/payload-types.type';
import { useAuth } from './auth/auth.context';
import useCustomLazyQuery from '../hooks/useCustomLazyQuery';
import { BatchContext } from './batch.context';
import useCustomMutation from '../hooks/useCustomMutation';

const ApplicationContext = createContext<{
  applications: Application[] | null;
  getApplicationsLoading: boolean;
  createApplication: any;
  createApplicationResult: any;
  error?: ApolloError;
  canApply: boolean | null;
}>({
  applications: null,
  getApplicationsLoading: true,
  createApplication: () => undefined,
  createApplicationResult: {},
  error: undefined,
  canApply: null,
});

const APPLICATIONS = gql`
  query getApplications {
    Applications(limit: 50, page: 1) {
      docs {
        id
        type
        batch {
          id
          name
          description
        }
        candidate_individual {
          id
        }
        candidate_team {
          id
        }
        individual_vertical
        individual_other_vertical
        motivation
        expectation
      }
    }
  }
`;

const CREATE_APPLICATION = gql`
  mutation createApplication($data: mutationApplicationInput!) {
    createApplication(data: $data) {
      id
    }
  }
`;

function ApplicationProvider({ children }: any) {
  const [applications, setApplications] = useState<Application[] | null>(null);
  const { user } = useAuth();
  const [getApplications, { loading, error, data }] = useCustomLazyQuery(APPLICATIONS, {fetchPolicy: 'network-only'});
  const [getApplicationsLoading, setGetApplicationsLoading] = useState(true);
  const { currentBatch } = useContext(BatchContext);
  const [createApplicationMutation, createApplicationResult] = useCustomMutation(CREATE_APPLICATION);

  const createApplication = async (data: any) => {
    if (user) {
      await createApplicationMutation({ variables: { data } });
      getApplications();
    }
  };

  // a user can apply if
  const canApply =
    // there is an upcoming batch
    currentBatch &&
    applications &&
    // the user has not already applied to the current batch
    !applications.find((e) => (typeof e.batch === 'string' ? e.batch : e.batch.id) === currentBatch.id);

  useEffect(() => {
    if (user) getApplications({ variables: { user: user.id } });
  }, [user, getApplications]);

  useEffect(() => {
    setGetApplicationsLoading(loading || applications === null);
  }, [getApplications, applications, loading]);

  useEffect(() => {
    if (data) {
      const {
        Applications: { docs },
      } = data;
      setApplications(docs);
    }
  }, [data]);

  const value = {
    applications,
    getApplicationsLoading,
    createApplication,
    createApplicationResult,
    error,
    canApply,
  };
  return <ApplicationContext.Provider value={value}>{children}</ApplicationContext.Provider>;
}

export default ApplicationProvider;
export { ApplicationContext };
