import React from 'react';
import { Form } from 'react-bootstrap';
import FormItemContainer from '../form-item-container/form-item-container.component';
import FormInputProps from '../../../types/FormInputProps';

type Props = FormInputProps<{
  text?: string;
}>;

const Checkbox: React.FC<Props> = React.forwardRef<HTMLInputElement, Props>(
  ({ name, required, label, error, text, onChange, onBlur, ...otherProps }, ref) => (
    <FormItemContainer {...{ required, text }}>
      <Form.Check
        type="checkbox"
        label={
          <>
            {label}
            {required && <span>*</span>}
          </>
        }
        {...{ name, required, ref, onChange, onBlur }}
        {...otherProps}
        isInvalid={!!error}
        feedback={error}
        feedbackType="invalid"
      />
    </FormItemContainer>
  ),
);

export default Checkbox;
