import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  TypedDocumentNode,
  useLazyQuery,
} from '@apollo/client';
import { useEffect, useState } from 'react';
import removeTypename from '../graphql/remove-typename';

type UseCustomLazyQueryType = (
  query: DocumentNode | TypedDocumentNode<any, OperationVariables>,
  options?: QueryHookOptions<any> | undefined,
) => any;

const useCustomLazyQuery: UseCustomLazyQueryType = (query, options) => {
  const [queryFunction, queryResult] = useLazyQuery(query, options);

  const [state, setState] = useState({ ...queryResult, data: removeTypename(queryResult.data) });

  useEffect(() => setState({ ...queryResult, data: removeTypename(queryResult.data) }), [queryResult]);

  return [queryFunction, state];
};

export default useCustomLazyQuery;
