import Select from '../../components/fields/select/select.component';
import TextInput from '../../components/fields/text-input/text-input.component';
import FormSection from '../../components/form-section/form-section.component';
import { useEffect, useState } from 'react';
import industries from '../../data/industries';
import Textarea from '../../components/fields/textarea/textarea.component';
import { gql, useLazyQuery } from '@apollo/client';
import { debounce } from 'lodash';
import { skillCategories } from '../../data/skills';
import SearchingSkillGroup from '../../components/fields/searching-skillgroup/searching-skillgroup.component';
import Checkbox from '../../components/fields/checkbox/checkbox.component';

type Props = {
  errors: any;
  control: any;
  register: any;
  // form variant: defines the context where the form is used. this allows better re-usage of the component
  variant: 'new-team' | 'update-team';
  // disabled: whether the form fields are disabled
  disabled?: boolean
};

const USERS_LIKE_USERNAME = gql`
  query ($username: String!) {
    Users(limit: 20, page: 1, where: { username: { like: $username } }) {
      docs {
        id
        first_name
        last_name
        username
      }
    }
  }
`;

const TeamData: React.FC<Props> = ({ errors, control, register, variant, disabled = false }) => {
  const [userDropdown, setUserDropdown] = useState([]);
  const [usersLikeUsername, usersLikeUsernameResult] = useLazyQuery(USERS_LIKE_USERNAME);

  useEffect(() => {
    if (
      !usersLikeUsernameResult.loading &&
      !usersLikeUsernameResult.error &&
      usersLikeUsernameResult.data?.Users?.docs
    ) {
      setUserDropdown(
        usersLikeUsernameResult.data?.Users?.docs.map((e) => ({
          value: e.id,
          label: `@${e.username} - ${e.first_name} ${e.last_name}`,
        })),
      );
    }
  }, [usersLikeUsernameResult.loading, usersLikeUsernameResult.error, usersLikeUsernameResult.data]);

  const debouncedQuery = debounce((e: any) => {
    if (e !== '') usersLikeUsername({ variables: { username: e } });
  }, 800);

  const handleInputChange = (e: any) => debouncedQuery(e);

  const searchingSkillsGroup = skillCategories.map((c) => (
    <SearchingSkillGroup
      key={c.id}
      name={c.label}
      fields={c.skills.map((s) => ({ name: `searchingSkills.${c.id}.${s.id}`, label: s.label }))}
      register={register}
      disabled={disabled}
      />
  ));

  return (
    <>
      <FormSection title={'General Information'}>
        <TextInput
          label="Team name"
          type="name"
          required={variant === 'new-team'}
          error={errors.name && `${errors.name?.message}`}
          {...register('name', { required: variant === 'new-team' })}
          disabled={disabled}
        />
        <Select
          name="members"
          label="Team Members"
          error={errors.members && `${errors.members?.message}`}
          multi={true}
          options={userDropdown}
          control={control}
          onInputChange={handleInputChange}
          placeholder="Search by username..."
          noOptionsMessage={() => (usersLikeUsernameResult.loading ? 'Loading...' : 'No users match')}
          disabled={disabled}
        />
        <Select
          name="industry"
          label="What is the reference vertical of your startup?"
          required
          error={errors.industry && `${errors.industry?.message}`}
          multi={false}
          options={industries}
          control={control}
          disabled={disabled}
        />
        <TextInput
          name="otherVertical"
          label="Is your startup focused on a more specific vertical?"
          type="text"
          error={errors.otherVertical && `${errors.otherVertical?.message}`}
          {...register('otherVertical', { required: false })}
          disabled={disabled}
        />
        <Textarea
          label="Can you add more information on the skills of your Team?"
          required
          error={errors.teamAdditionalInfo && `${errors.teamAdditionalInfo?.message}`}
          {...register('teamAdditionalInfo', { required: true })}
          disabled={disabled}
        />
        <Textarea
          label="What's the problem you are trying to solve?"
          required
          error={errors.problemDescription && `${errors.problemDescription?.message}`}
          {...register('problemDescription', { required: true })}
          disabled={disabled}
        />
        <Textarea
          label="What solution are you trying to develop?"
          required
          error={errors.solutionDescription && `${errors.solutionDescription?.message}`}
          {...register('solutionDescription', { required: true })}
          disabled={disabled}
        />
      </FormSection>
      <FormSection title={'Matching'}>
        <Checkbox
          label={<span>We are looking for new team members.</span>}
          error={errors.lookingForMembers && `${errors.lookingForMembers?.message}`}
          {...register('lookingForMembers', { required: true })}
          text ='We will recommend you potential matches in case of selection.'
          disabled={disabled}
        />
        <p className='mt-4'>
          Select the skills/competences that you are looking for in the new member(s) of your Team. For each
          skill/competence, select the value in the range 0 (I don't need people with this skill) to 10 (I extremely need people with this skill) depending on your team needs.
        </p>
        {searchingSkillsGroup}
      </FormSection>
    </>
  );
};

export default TeamData;
