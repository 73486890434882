import { Alert, Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import TextInput from '../../../components/fields/text-input/text-input.component';
import { string, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth } from '../../../contexts/auth/auth.context';

const forgotPasswordSchema = object({
  email: string().required().email(),
});

function PasswordForgot() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(forgotPasswordSchema), mode: 'onTouched' });

  const { passwordForgot, passwordForgotResult } = useAuth();

  const sendEmail = (data: any) => {
    passwordForgot(data.email);
  };

  return (
    <>
      <h1>Recover Password</h1>
      <p>
        Please provide here the email address associated with your account. A message will be sent to you with further
        instructions on how to recover your password.
      </p>
      {passwordForgotResult?.data?.forgotPasswordUser && <Alert variant="success">If an account is registered with the provided email, you should have received a message. Check your inbox!</Alert>}
      <Form noValidate onSubmit={handleSubmit(sendEmail)}>
        <TextInput
          label="Email"
          type="email"
          required
          error={errors.email && `${errors.email?.message}`}
          {...register('email', { required: true })}
        />
        <Button variant="primary" type="submit">
          Send
        </Button>
      </Form>
      <Link to="/auth/sign-in">Login</Link>
      <br />
      <Link to="/auth/sign-up">Create an account</Link>
    </>
  );
}

export default PasswordForgot;
