import { array, object, ref, string } from 'yup';
import FormSchemaBuilder from '../../types/form-schema-builder.type';

const schema: FormSchemaBuilder<'sign-up' | 'profile'> = (variant) =>
  object({
    ...(variant === 'sign-up'
      ? {
          username: string()
            .required()
            .matches(/^[a-z0-9_]+$/gm, 'Username can only contain alphanumerical characters or underscores.')
            .max(32, 'Username can be max 32 characters.'),
        }
      : {}),
    email: string().required().email(),
    password: variant === 'sign-up' ? string().required() : string().optional(),
    repeatPassword: (variant === 'sign-up' ? string().required() : string().optional()).oneOf([ref('password')], 'Passwords must match'),
    firstName: string().required(),
    lastName: string().required(),
    linkedin: string().required().url(),
    website: string().optional().url(),
    associations: array().optional().default([]),
    otherAssociations: string().optional(),
  });

export default schema;
