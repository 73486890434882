import { Batch } from "../../types/payload-types.type";
import { toReadableString } from "../../utils/date.utils";
import FormSection from "../form-section/form-section.component";

const ApplicationInformation: React.FC<{ batch: Batch }> = ({ batch }) => {
    return (
      <FormSection title="Information">
        <div className='mb-3'>
          Applying to: <b>{batch.name}</b>
          <br />
          Application Opening: <b>{toReadableString(new Date(batch.application_opening))}</b>
          <br />
          Application Closing: <b>{toReadableString(new Date(batch.application_closing))}</b>
        </div>
        {batch.description && (
          <p>
            {batch.description}
          </p>
        )}
      </FormSection>
    );
  };

  export default ApplicationInformation;