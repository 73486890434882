import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import TeamData from '../../forms/team-data/team-data.component';
import schema from '../../forms/team-data/team-data.schema';
import CustomButton from '../../components/custom-button/custom-button.component';
import { TeamContext } from '../../contexts/team.context';
import { fromFormDataToUpdateTeamData, removeNullFields } from '../../utils/gql.utils';
import { useAuth } from '../../contexts/auth/auth.context';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Col, Row } from 'react-bootstrap';
import industries from '../../data/industries';
import ButtonLink from '../../components/button-link/button-link.component';

const UpdateTeam: React.FC = () => {
  let { slug } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { teams, updateTeam, updateTeamResult } = useContext(TeamContext);
  const team = teams ? teams.filter((e) => e.slug === slug)[0] : null;

  const canEdit = team ? (typeof team.owner === 'string' ? team.owner : team.owner.id) === user?.id : false;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema('update-team')),
    mode: 'onTouched',
  });

  useEffect(() => {
    if (team) {
      // build default values object
      const defaultValues = {
        name: team.name,
        members: team.members
          .map((e: any) => ({
            value: e.member.id,
            label: `@${e.member.username} - ${e.member.first_name} ${e.member.last_name} ${
              e.member.id === (typeof team.owner == 'string' ? team.owner : team.owner.id) ? '(Owner)' : ''
            }`,
            isFixed: e.member.id === (typeof team.owner == 'string' ? team.owner : team.owner.id),
          })),
        industry: { value: team.industry, label: industries.find((e) => e.value === team.industry)?.label },
        otherVertical: team.other_vertical,
        teamAdditionalInfo: team.team_additional_info,
        problemDescription: team.problem_description,
        solutionDescription: team.solution_description,
        lookingForMembers: team.looking_for_members,
        searchingSkills: removeNullFields(team.searching_skills),
      };
      // Loop through the default values and set each value using setValue
      Object.keys(defaultValues).forEach((key) => {
        setValue(key, defaultValues[key]);
      });
    }
  }, [team, setValue]);

  const submitUpdateTeamForm = async (data: any) => {
    if (team && user) await updateTeam(team.id, fromFormDataToUpdateTeamData(data, user.id));
  };

  const success =
    updateTeamResult.called && !updateTeamResult.loading && !updateTeamResult.error && updateTeamResult.data;
  const error = updateTeamResult.called && !updateTeamResult.loading && updateTeamResult.error;

  // navigate to dashboard if team update was successful
  useEffect(() => {
    if (success) {
      updateTeamResult.reset();
      navigate(`/teams?update=${updateTeamResult.data.updateTeam.slug}`);
    }
  }, [
    navigate,
    success,
    updateTeamResult,
  ]);

  return (
    <>
      {error && <Alert variant="danger">Something went wrong while updating the team. Please try again.</Alert>}
      <h1 className="pb-4">My team</h1>
      {!canEdit && (
        <p>
          You cannot edit this page since you are not the team owner. Please contact your team owner if you think
          something in the following information should be changed.
        </p>
      )}
      <TeamData {...{ register, control, errors, setValue }} variant="update-team" disabled={!canEdit} />
      <Row className="justify-content-between">
        <Col xs="auto">
          <ButtonLink href="/teams">Back</ButtonLink>
        </Col>
        {canEdit && (
          <Col xs="auto">
            <CustomButton onClick={handleSubmit(submitUpdateTeamForm)}>Update</CustomButton>
          </Col>
        )}
      </Row>
    </>
  );
};

export default UpdateTeam;
