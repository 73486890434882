import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import MultistepFormButtons from '../../../components/multistep-form-buttons/multistep-form-buttons.component';
import useMultistepForm from '../../../hooks/useMultistepForm';
import { SignUpForm } from './sign-up.component';
import GeneralInformation from '../../../forms/general-information/general-information.component';
import schema from '../../../forms/general-information/general-information.schema';

function SignUpPage1() {
  const { next, prev, currentStep, steps, currentStepData } = useMultistepForm(SignUpForm);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema('sign-up')), mode: 'onTouched', defaultValues: currentStepData });

  return (
    <>
      <GeneralInformation {...{ errors, control, register }} variant="sign-up" />
      <MultistepFormButtons next={handleSubmit(next)} prev={handleSubmit(prev)} {...{ currentStep, steps }} />
    </>
  );
}

export default SignUpPage1;
