import { useContext } from 'react';
import { useAuth } from '../../contexts/auth/auth.context';
import { ApplicationContext } from '../../contexts/application.context';
import ApplicationsSection from '../../components/applications-section/applications-section.component';
import { Alert, Spinner } from 'react-bootstrap';
import { BatchContext } from '../../contexts/batch.context';
import { toReadableString } from '../../utils/date.utils';
import ButtonLink from '../../components/button-link/button-link.component';
import { useSearchParams } from 'react-router-dom';

function Dashboard() {
  const { user } = useAuth();
  const { currentBatch, currentBatchStatus } = useContext(BatchContext);
  const { applications, getApplicationsLoading, canApply } = useContext(ApplicationContext);
  const [searchParams] = useSearchParams();
  const successCreateApplication = searchParams.get('apply-success');

  const applySectionContent =
    !currentBatch || !currentBatchStatus ? (
      <p>
        Currently, there is no planned upcoming batch. Make sure to sign up to our newsletter so you don't miss updates
        about Astra and future editions of our Program.
      </p>
    ) : !canApply ? (
      <p>
        You have already applied to the current Batch of Astra Incubator. You will receive news from our side as soon as
        we evaluate your submission.
      </p>
    ) : currentBatchStatus === 'open' ? (
      <>
        <p>
          Applications for {currentBatch.name} are open! You can apply until{' '}
          {toReadableString(new Date(currentBatch.application_closing))}.
        </p>
        <ButtonLink href={`/apply`}>Apply Now</ButtonLink>
      </>
    ) : (
      <p>
        Applications for {currentBatch.name} are opening on{' '}
        {toReadableString(new Date(currentBatch.application_opening))}.
      </p>
    );
  return (
    <>
      {getApplicationsLoading || !user ? (
        <Spinner />
      ) : (
        <>
          {successCreateApplication && <Alert variant="success">Thank you for your application! We will get back to you as soon as we have reviewed your submission.</Alert>}
          <h1 className="mb-5">Welcome, {user?.first_name}!</h1>
          <h3 className="mb-4">Apply</h3>
          <div className="mb-5">{applySectionContent}</div>
          <ApplicationsSection
            title="Past applications"
            applications={applications || []}
            message="You have not applied to any batch of Astra Incubator yet."
          />
        </>
      )}
    </>
  );
}

export default Dashboard;
