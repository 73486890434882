import { DocumentNode, OperationVariables, QueryHookOptions, TypedDocumentNode, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import removeTypename from '../graphql/remove-typename';

type UseCustomQueryType = (
  query: DocumentNode | TypedDocumentNode<any, OperationVariables>,
  options?: QueryHookOptions<any> | undefined,
) => any;

const useCustomQuery: UseCustomQueryType = (query, options) => {
  const queryResult = useQuery(query, options);

  const [state, setState] = useState({ ...queryResult, data: removeTypename(queryResult.data) });

  useEffect(() => setState({ ...queryResult, data: removeTypename(queryResult.data) }), [queryResult]);

  return state;
};

export default useCustomQuery;
