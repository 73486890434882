import { object, boolean, string } from 'yup';
import { skillCategories } from '../../data/skills';
import FormSchemaBuilder from '../../types/form-schema-builder.type';

const schema: FormSchemaBuilder<'sign-up' | 'profile'> = (variant) =>
  object({
    ...skillCategories.reduce((acc, category) => {
      return {
        ...acc,
        [category.id]: object(
          category.skills.reduce((acc, skill) => {
            return { ...acc, [skill.id]: boolean().optional() };
          }, {}),
        ),
      };
    }, {}),
    moreInfo: string().required(),
  });

export default schema;
