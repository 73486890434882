import React from 'react';
import { Col, Row } from 'react-bootstrap';
import TeamCard from '../../components/team-card/team-card.component';
import ComponentProps from '../../types/ComponentProps';
import TeamNewButton from '../team-new-button/team-new-button.component';

type Props = ComponentProps<{
  teams: any[];
  title: string;
  newButton?: boolean;
  message?: string;
}>;

const TeamsSection: React.FC<Props> = ({ teams, title, className, newButton, message }) => {
  const teamCards = [
    ...(newButton ? [<TeamNewButton url="new" className="h-100" />] : []),
    ...teams.map((e) => <TeamCard className="w-100" team={e} />),
  ];
  return (
    <div className={className}>
      <h3 className="mb-3">{title}</h3>
      {teamCards.length === 0 && (
        <Col xs={12}>
          <p>{message}</p>
        </Col>
      )}
      <Row xs={1} sm={2} md={3} lg={3}>
        {teamCards.map((e, i) => (
          <Col key={i} className="px-3 py-3">
            {e}
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default TeamsSection;
