import React, { createContext, useContext, useEffect, useState } from 'react';

import { User } from '../../types/payload-types.type';
import { AuthContext } from './auth.context.types';
import { gql } from '@apollo/client';
import useCustomQuery from '../../hooks/useCustomQuery';
import useCustomMutation from '../../hooks/useCustomMutation';

const Context = createContext({} as any); //as AuthContext

const CREATE_USER = gql`
  mutation ($data: mutationUserInput!) {
    createUser(data: $data, draft: false) {
      id
      email
    }
  }
`;

const FORGOT_PASSWORD = gql`
  mutation ($email: String!) {
    forgotPasswordUser(email: $email)
  }
`;

const RESET_PASSWORD = gql`
  mutation ($password: String, $token: String) {
    resetPasswordUser(password: $password, token: $token) {
      user {
        id
      }
    }
  }
`;

const QUERY_ME = gql`
  query {
    meUser {
      user {
        id
        username
        first_name
        last_name
        linkedin_url
        website_url
        skills {
          business {
            accounting
            fundraising
            investor_relations
            pitching
            planning_and_strategy
            product_management
          }
          computer_science {
            ai_and_machine_learning
            backend_development
            blockchain
            cybersecurity
            frontend_development
            embedded_development
            other
            quantum_computing
          }
          engineering {
            aerospace
            biomedical
            biotechnology
            electronic
            mechanical
            nuclear
          }
          content_creation {
            audio
            digital_art
            photography
            storytelling
            text
            videomaking
          }
          marketing_and_public_relations {
            seo
            social_media
          }
          miscellanea {
            business_analytics
            chemistry
            law
            ux_and_ui
          }
        }
        associations {
          id
          name
        }
        other_associations
        more_info
        how_did_you_find_us
        privacy_policy
        email
      }
    }
  }
`;

const LOGIN_USER = gql`
  mutation ($email: String, $password: String) {
    loginUser(email: $email, password: $password) {
      exp
      token
      user {
        id
        username
        first_name
        last_name
        linkedin_url
        website_url
        skills {
          business {
            accounting
            fundraising
            investor_relations
            pitching
            planning_and_strategy
            product_management
          }
          computer_science {
            ai_and_machine_learning
            backend_development
            blockchain
            cybersecurity
            frontend_development
            embedded_development
            other
            quantum_computing
          }
          engineering {
            aerospace
            biomedical
            biotechnology
            electronic
            mechanical
            nuclear
          }
          content_creation {
            audio
            digital_art
            photography
            storytelling
            text
            videomaking
          }
          marketing_and_public_relations {
            seo
            social_media
          }
          miscellanea {
            business_analytics
            chemistry
            law
            ux_and_ui
          }
        }
        associations {
          id
          name
        }
        other_associations
        more_info
        how_did_you_find_us
        privacy_policy
        email
      }
    }
  }
`;

const LOGOUT_USER = gql`
  mutation {
    logoutUser
  }
`;

const UPDATE_USER = gql`
  mutation ($id: String!, $data: mutationUserUpdateInput!) {
    updateUser(id: $id, data: $data, draft: false) {
      id
      username
      first_name
      last_name
      linkedin_url
      website_url
      skills {
        business {
          accounting
          fundraising
          investor_relations
          pitching
          planning_and_strategy
          product_management
        }
        computer_science {
          ai_and_machine_learning
          backend_development
          blockchain
          cybersecurity
          frontend_development
          embedded_development
          other
          quantum_computing
        }
        engineering {
          aerospace
          biomedical
          biotechnology
          electronic
          mechanical
          nuclear
        }
        content_creation {
          audio
          digital_art
          photography
          storytelling
          text
          videomaking
        }
        marketing_and_public_relations {
          seo
          social_media
        }
        miscellanea {
          business_analytics
          chemistry
          law
          ux_and_ui
        }
      }
      associations {
        id
        name
      }
      other_associations
      more_info
      how_did_you_find_us
      privacy_policy
      email
    }
  }
`;

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>();
  const [isLoading, setIsLoading] = useState(true);
  const [createUser, signUpResult] = useCustomMutation(CREATE_USER);
  const [forgotPassword, passwordForgotResult] = useCustomMutation(FORGOT_PASSWORD);
  const [resetPassword, passwordResetResult] = useCustomMutation(RESET_PASSWORD);
  const [loginUser, signInResult] = useCustomMutation(LOGIN_USER);
  const [logoutUser, signOutResult] = useCustomMutation(LOGOUT_USER);
  const [updateUser, updateProfileResult] = useCustomMutation(UPDATE_USER);

  const { loading, error, data } = useCustomQuery(QUERY_ME, { fetchPolicy: 'no-cache' });
  const meUserLoading = loading;
  const meUserError = error;
  const meUser = data?.meUser;

  const signUp = (userData: any) => createUser({ variables: { data: userData } });
  const passwordForgot = (email: string) => forgotPassword({ variables: { email: email } });
  const passwordReset = (password: string, token: string) => resetPassword({ variables: { password, token } });
  const signIn = async (email: string, password: string) => {
    try {
      const { data } = await loginUser({ variables: { email, password } });
      setUser(data?.loginUser?.user);
    } catch (e) {}
  };
  const signOut = () => {
    logoutUser();
    setUser(null);
  };

  const updateProfile = async (id: string, updateData: any) => {
    try {
      const { data } = await updateUser({ variables: { id, data: updateData } });
      setUser(data?.updateUser);
    } catch (e) {}
  };

  const isAuthenticated = user !== null && user!==undefined;

  // On mount, get user and set update user anytime it changes
  useEffect(() => {
    if (!meUserLoading) setUser(meUser?.user);
  }, [meUserLoading, meUserError, meUser]);

  useEffect(() => {
    setIsLoading(false);
  }, [user]);

  return (
    <Context.Provider
      value={{
        user,
        updateProfile,
        updateProfileResult,
        signIn,
        signInResult,
        signOut,
        signOutResult,
        signUp,
        signUpResult,
        passwordForgot,
        passwordForgotResult,
        passwordReset,
        passwordResetResult,
        isAuthenticated,
        isLoading,
      }}
    >
      {children}
    </Context.Provider>
  );
};

type UseAuth = () => AuthContext; // eslint-disable-line no-unused-vars

export const useAuth: UseAuth = () => useContext(Context);
