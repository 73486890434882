import { Button } from 'react-bootstrap';

type Props = {
  currentStep: number;
  steps: number;
  prev?: () => void;
  next?: () => void;
  prevLabel?: string;
  nextLabel?: string;
};

const MultistepFormButtons: React.FC<Props> = ({ currentStep, steps, prev, next, prevLabel, nextLabel }) => (
  <>
    {currentStep > 1 && (
      <Button variant="primary" type="button" onClick={prev}>
        {prevLabel || 'Previous'}
      </Button>
    )}
    {currentStep < steps && (
      <Button variant="primary" type="button" onClick={next}>
        {nextLabel || 'Next'}
      </Button>
    )}
    {currentStep === steps && (
      <Button variant="primary" type="button" onClick={next}>
        {nextLabel || 'Sign up'}
      </Button>
    )}
  </>
);

export default MultistepFormButtons;
