import { Alert, Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import TextInput from '../../../components/fields/text-input/text-input.component';
import { string, object, ref } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth } from '../../../contexts/auth/auth.context';
import ButtonLink from '../../../components/button-link/button-link.component';

const forgotPasswordSchema = object({
  password: string().required(),
  password_dup: string()
    .required()
    .oneOf([ref('password')], 'Passwords do not match.'),
});

function PasswordReset() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(forgotPasswordSchema), mode: 'onChange' });

  const { passwordReset, passwordResetResult } = useAuth();

  const [searchParams,] = useSearchParams();

  const sendEmail = (data: any) => {
    const token = searchParams.get('token') || ''
    passwordReset(data.password, token);
  };

  return (
    <>
      <h1>Reset Password</h1>
      {passwordResetResult?.data?.resetPasswordUser ? (
        <>
        <Alert variant="success">You have successfully reset your password.</Alert>
        <ButtonLink href="/auth/sign-in">Sign In</ButtonLink>
        </>
      ) : (
        <>
          <p>Please provide here the new credentials to access your account.</p>
          <Form noValidate onSubmit={handleSubmit(sendEmail)}>
            <TextInput
              label="New Password"
              type="password"
              required
              error={errors.password && `${errors.password?.message}`}
              {...register('password', { required: true })}
            />
            <TextInput
              label="Retype new Password"
              type="password"
              required
              error={errors.password_dup && `${errors.password_dup?.message}`}
              {...register('password_dup', { required: true })}
            />
            <Button variant="primary" type="submit">
              Change password
            </Button>
          </Form>
        </>
      )}
    </>
  );
}

export default PasswordReset;
