import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { StyledFooter } from './footer.styles';

const CustomFooter: React.FC = () => {
  return (
    <StyledFooter fluid className="">
      <Row className="justify-content-center">
        <Col xs="auto">
          <p>
            Need help? Contact us at <a href="mailto:apply@astraincubator.com">apply@astraincubator.com</a>
          </p>
          <p className="text-muted">© Copyright {new Date().getFullYear()} Astra Incubator. All rights Reserved.</p>
        </Col>
      </Row>
    </StyledFooter>
  );
};

export default CustomFooter;
