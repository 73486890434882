import React from 'react';
import ComponentProps from '../../types/ComponentProps';
import CardBase from '../card-base/card-base.component';

type Props = ComponentProps<{
    url: string;
  }>;

const TeamNewButton: React.FC<Props> = ({ url, ...props }) => (
  <CardBase title='Create New' subtitle='' variant='primary' url={url} {...props}/>
);

export default TeamNewButton;
