
function Home() {
    return <div>
        <h1 className="mb-4">Welcome to the Astra Incubator Application Portal</h1>
        <p className="mb-5">Welcome to Astra Incubator! Ready to take the next step in your entrepreneurial journey? Follow these steps to apply to our program:</p>
        <h2 className="mb-4">How to Apply</h2>

        <div>
            <h3>Register or Login</h3>
            <p>Create an account on our platform or log in if you already have an account. Complete the entire registration form to set up your profile. Please ensure that you provide accurate and detailed information in the form, as your data will be used in evaluating your application.</p>

            <h3>Create or Join a Team</h3>
            <p>Interested in joining an existing team? Reach out to the team owner and ask to be added. Want to start your own team? You can create one within the Teams section of the platform.</p>

            <h3>Submit Your Application</h3>
            <p>Little before the opening of the application window, the dashboard will inform you that a new batch of Astra will run soon. You will be able to apply as soon as the applications open. There are two ways to apply:</p>
            <ul>
                <li><strong>Individual Application:</strong> If you're applying solo, you can submit your application directly through your profile dashboard.</li>
                <li><strong>Team Application:</strong> Only the team owner can submit the application. Once your team is ready, the owner can initiate the application process from their profile.</li>
            </ul>

            <h3>Receive Feedback</h3>
            <p>After submitting your application, our team will review it carefully. You'll receive feedback and updates on the status of your application via email.</p>
        </div>

        <p>Have questions or need assistance? Contact us at <a href="mailto:apply@astraincubator.com">apply@astraincubator.com</a>.</p>
    </div>
}

export default Home