import ComponentProps from '../../types/ComponentProps';
import React from 'react';
import CardBase from '../card-base/card-base.component';

type Props = ComponentProps<{
  team: {
    name: string
    industry: string
    slug: string
  };
}>;


const TeamCard: React.FC<Props> = ({ team, ...props }) => (
  <CardBase title={team.name} subtitle={team.industry} variant='light' url={`/teams/${team.slug}`} {...props}/>
);

export default TeamCard;
