import { Col, FormControl, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

/**
 *
 * A controlled input group for skills.
 * Each skills is represented by a number input.
 *
 */
type Props = {
  name: string;
  fields: {
    name: string;
    label: string;
    initial?: number;
  }[];
  register: any;
  disabled?: boolean;
};

/**
 *
 * A searching skill group. Optionally, a default value for the skills can be supplied.
 * The Skill Group is modeled as a group of uncontrolled numerical inputs.
 */
const SearchingSkillGroup: React.FC<Props> = ({ name, fields, register, disabled }) => {
  return (
    <div className="mb-4">
      <h5 className="mb-3">{name}</h5>
      {fields.map((e, i) => (
        <Form.Group key={i} as={Row} className="align-items-center mb-3" controlId="formPlaintextEmail">
          <Form.Label column xs="12" sm="6" md="4">
            {e.label}
          </Form.Label>
          <Col xs="12" sm="6" md="4">
            <FormControl
              type="number"
              defaultValue={e.initial ?? 0}
              key={e.name}
              name={e.name}
              min={0}
              max={10}
              error=""
              disabled={disabled}
              {...register(e.name)}
            />
          </Col>
        </Form.Group>
      ))}
    </div>
  );
};

export default SearchingSkillGroup;
