import React, { useContext, useEffect } from 'react';
import { ApplicationContext } from '../../contexts/application.context';
import { useParams } from 'react-router-dom';
import schema from '../../forms/application-data/application-data.schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import FormSection from '../../components/form-section/form-section.component';
import ApplicationData from '../../forms/application-data/application-data.component';
import { TeamContext } from '../../contexts/team.context';
import industries from '../../data/industries';

const Application: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { applications, getApplicationsLoading } = useContext(ApplicationContext);
  const { teams } = useContext(TeamContext);
  // Find the application with the given ID
  const application = applications ? applications.find((app) => app.id === id) : null;

  const {
    register,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema('new-application')),
    mode: 'onTouched',
  });

  useEffect(() => {
    if (application && teams) {
      // build default values object
      const team =
        application.candidate_team !== null
          ? teams.find(
              (team) =>
                team.id ===
                (typeof application.candidate_team === 'object' && application.candidate_team !== null
                  ? application.candidate_team.id
                  : application.candidate_team),
            )
          : null;

      const defaultValues = {
        type: application.type,
        candidateTeam: team ? { value: team.id, label: team.name } : null,
        motivation: application.motivation,
        expectation: application.expectation,
        individualVertical: application.individual_vertical
          ? application.individual_vertical.map((e) => industries.find((i) => i.value === e))
          : null,
        invididualOtherVertical: application.individual_other_vertical,
      };
      // Loop through the default values and set each value using setValue
      Object.keys(defaultValues).forEach((key) => {
        setValue(key, defaultValues[key]);
      });
    }
  }, [application, setValue, teams]);

  if (getApplicationsLoading) {
    return <div>Loading...</div>; // Display a spinner while loading applications
  }

  if (!application) {
    return <div>Application not found</div>;
  }

  const { batch } = application;
  return (
    <div>
      <h1 className='mb-5'>Your Application</h1>
      {typeof batch !== 'string' && (
        <FormSection title="Information">
          <div className="mb-3">
            Applied to: <b>{batch.name}</b>
            <br />
          </div>
          {batch.description && <p>{batch.description}</p>}
        </FormSection>
      )}
      <ApplicationData {...{ register, control, errors, setValue, watch }} disabled />
    </div>
  );
};

export default Application;
