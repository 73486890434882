import React from 'react';

type Props = {
  title: string;
  description?: string;
  children?: React.ReactNode;
};

const FormSection: React.FC<Props> = ({ title, description, children }) => (
  <div className="mb-5">
    <h3 className="mb-3">{title}</h3>
    {description && <p className="mb-3">{description}</p>}
    {children}
  </div>
);

export default FormSection;
