import { useEffect } from 'react';
import { useAuth } from '../../../contexts/auth/auth.context';
import { useNavigate } from 'react-router-dom';

function SignOut() {
  const { signOut, signOutResult } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    signOut();
  }, []);

  useEffect(() => {
    const { loading, error, data } = signOutResult;
    if (!loading && !error && data?.logoutUser) navigate('/');
  }, [signOutResult]);
  return (
    <>
      <p>Signing you out...</p>
    </>
  );
}

export default SignOut;
