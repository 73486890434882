const skillCategories = [
  {
    id: 'business',
    label: 'Business',
    skills: [
      { id: 'accounting', label: 'Accounting' },
      { id: 'fundraising', label: 'Fundraising' },
      { id: 'investor_relations', label: 'Investor Relations' },
      { id: 'pitching', label: 'Pitching' },
      { id: 'planning_and_strategy', label: 'Planning and Strategy' },
      { id: 'product_management', label: 'Product Management' },
    ],
  },
  {
    id: 'computer_science',
    label: 'Computer Science',
    skills: [
      { id: 'ai_and_machine_learning', label: 'AI and Machine Learning' },
      { id: 'backend_development', label: 'Backend Development' },
      { id: 'blockchain', label: 'Blockchain' },
      { id: 'cybersecurity', label: 'CyberSecurity' },
      { id: 'frontend_development', label: 'Frontend Development' },
      { id: 'embedded_development', label: 'Embedded Development' },
      { id: 'other', label: 'Other' },
    ],
  },
  {
    id: 'engineering',
    label: 'Engineering',
    skills: [
      { id: 'aerospace', label: 'Aerospace' },
      { id: 'biotechnology', label: 'Biotechnology' },
      { id: 'electronic', label: 'Electronic' },
      { id: 'mechanical', label: 'Mechanical' },
      { id: 'nuclear', label: 'Nuclear' },
    ],
  },
  {
    id: 'content_creation',
    label: 'Content Creation',
    skills: [
      { id: 'audio', label: 'Audio' },
      { id: 'digital_art', label: 'Digital Art' },
      { id: 'photography', label: 'Photography' },
      { id: 'storytelling', label: 'Storytelling' },
      { id: 'text', label: 'Text' },
      { id: 'videomaking', label: 'Videomaking' },
    ],
  },
  {
    id: 'marketing_and_public_relations',
    label: 'Marketing and Public Relations',
    skills: [
      { id: 'seo', label: 'SEO' },
      { id: 'social_media', label: 'Social Media' },
    ],
  },
  {
    id: 'miscellanea',
    label: 'Miscellanea',
    skills: [
      { id: 'business_analytics', label: 'Business Analytics' },
      { id: 'chemistry', label: 'Chemistry' },
      { id: 'ux_and_ui', label: 'UX and UI' },
    ],
  },
];

export { skillCategories };
