import { ApolloError, gql } from '@apollo/client';
import { createContext, useEffect, useState } from 'react';
import useCustomQuery from '../hooks/useCustomQuery';

const AssociationContext = createContext<{ associations: any; loading: boolean; error: ApolloError | undefined }>({
  associations: [],
  loading: true,
  error: undefined,
});

const ASSOCIATIONS = gql`
  query {
    Associations {
      docs {
        id
        name
        slug
      }
    }
  }
`;

function AssociationProvider({ children }: any) {
  const [associations, setAssociations] = useState([]);

  const { loading, error, data } = useCustomQuery(ASSOCIATIONS);

  useEffect(() => {
    if (data) {
      const {
        Associations: { docs },
      } = data;

      setAssociations(docs);
    }
  }, [data]);

  const value = { associations, loading, error };
  return <AssociationContext.Provider value={value}>{children}</AssociationContext.Provider>;
}

export default AssociationProvider;
export { AssociationContext };
